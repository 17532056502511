import { Extension } from "@tiptap/core";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import Document from "@tiptap/extension-document";
import Heading from "@tiptap/extension-heading";
import History from "@tiptap/extension-history";
import Image from "@tiptap/extension-image";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import Youtube from "@tiptap/extension-youtube";
import { useEditor } from "@tiptap/react";
import ImageResize from "tiptap-extension-resize-image";
import {
  PageNode,
  PaginationExtension,
  PaginationPlugin,
} from "./paginationExtension";

const LiteralTab = Paragraph.extend({
  name: "literalTab",

  addKeyboardShortcuts() {
    return {
      Tab: () => {
        if (
          this.editor.isActive("listItem") &&
          this.editor.can().sinkListItem("listItem")
        ) {
          return this.editor.chain().focus().sinkListItem("listItem").run();
        }
        return this.editor.commands.insertContent("\t");
      },
    };
  },
});

export const DefaultEditor = (isEditable = true) =>
  useEditor({
    extensions: [
      PaginationPlugin,
      PageNode,
      PaginationExtension,
      Document,
      Paragraph,
      Text,
      Heading.configure({
        levels: [1, 2],
      }),
      Bold,
      Italic,
      Underline,
      Link.configure({
        defaultProtocol: "https",
      }),
      Image.configure({
        inline: true,
      }),
      Table.configure({
        resizable: true,
      }),
      TextAlign.configure({
        types: ["heading", "paragraph", "tableCell", "tableHeader"],
        alignments: ["left", "right", "center"],
      }),
      BulletList,
      OrderedList,
      ListItem,
      TableHeader,
      TableRow,
      TableCell,
      Youtube,
      History,
      ImageResize,
      LiteralTab,
    ],
    content: `<h1 style="text-align: center;"">Title</h1><p>Example Text</p>`,
    autofocus: true,
    editable: isEditable,
    injectCSS: false,
  });
