import React, { useEffect, useState } from "react";
import { apiUrl } from "../../../util/config";
import axios from "axios";

const CategorySelect = ({ categoryId, onCategoryChange, hasNull }) => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [category, setCategory] = useState(categoryId || null);

  useEffect(() => {
    const fetchNestedCategories = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${apiUrl}/api/nested-categories`, {
          withCredentials: true, // Include cookies in the request
        });
        
        if (response.data.success) {
         
          const result = response.data.data;
          setCategories(result);
          if (category === null) {
            setCategory(result[0].ID);
            onCategoryChange(result[0].ID);
          }
        } else {
          throw new Error("Error fetching nested categories.");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNestedCategories();
  }, []);

  if (isLoading) return <p>Loading categories...</p>;
  if (error) return <p>Error loading categories: {error}</p>;

  const recursiveCategoryOptions = (categories, prefix = "") => {
    return categories.map((category) => (
      <React.Fragment key={category.ID}>
        <option value={category.ID}>{prefix + category.Name}</option>
        {category.sub_categories &&
          recursiveCategoryOptions(category.sub_categories, prefix + "--")}
      </React.Fragment>
    ));
  };

  return (
    <select
      value={category ?? ""}
      onChange={(e) => {
        onCategoryChange(e.target.value === "" ? null : e.target.value);
        setCategory(e.target.value);
      }}
    >
      {hasNull && <option value="">None</option>}
      {recursiveCategoryOptions(categories)}
    </select>
  );
};

export default CategorySelect;
