import React from "react";

const NoImageSVG = ({ onClick }) => {
  return (
    <img
      src="/icons/image.svg"
      alt="image"
      onClick={onClick}
      style={{
        cursor: "pointer",
        width: "200px",
        height: "200px",
      }}
    />
  );
};

export default NoImageSVG;
