import React, { useEffect, useState } from "react";

const DirectionIcon = ({ chosen, size = "50" }) => {
  const [hasDirection, setHasDirection] = useState(chosen);

  useEffect(() => {
    setHasDirection(chosen);
  }, [chosen]);

  const handleClick = () => {
    setHasDirection(!hasDirection);
    //onclick(!hasGrid);
  };

  return (
    <div className="grid-icon" onClick={handleClick}>
      {DirectionSVG(hasDirection, size)}
    </div>
  );
};

const DirectionSVG = (hasDirection, size) => {
  if (hasDirection) {
    return (
      <img
        src="/icons/page-vertical.svg"
        alt="page vertical"
        width={size.size}
        height={size.size}
      />
    );
  }
  return (
    <img
      src="/icons/page-horizontal.svg"
      alt="page horizontal"
      width={size.size}
      height={size.size}
    />
  );
};

export default DirectionIcon;
