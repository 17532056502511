import React from "react";

const TextIcon = ({ onClick }) => {
  return (
    <img
      src="/icons/text.svg"
      alt="text"
      onClick={onClick}
      style={{
        cursor: "pointer",
        height: "30px",
        width: "30px",
        fill: "inherit",
      }}
    />
  );
};

export default TextIcon;
