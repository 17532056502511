import React from "react";

const VideoIcon = ({ onClick }) => {
  return (
    <img
      src="/icons/video.svg"
      alt="video"
      onClick={onClick}
      width={30}
      height={30}
    />
  );
};

export default VideoIcon;
