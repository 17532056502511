import React from "react";

const PictureIcon = ({ onClick, size = "30px" }) => {
  return (
    <img
      src="/icons/image.svg"
      alt="image"
      onClick={onClick}
      style={{ cursor: "pointer", height: size, width: size, fill: "inherit" }} // Adjust size as needed
    />
  );
};

export default PictureIcon;
