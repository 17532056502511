import { set } from "date-fns";
import React, { useEffect, useState } from "react";

const DraggableDiv = ({ startPosition, onDragEnd }) => {
  // State to keep track of the current position and start position of the drag
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startDragPosition, setStartDragPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);

  // Effect to set the initial position based on prop
  useEffect(() => {
    if (startPosition === "left") {
      setPosition({ x: 0, y: 0 });
    } else if (startPosition === "right") {
      // Assuming the div is positioned absolutely and right: 0 would mean aligning to the screen width
      setPosition({ x: 0, y: 0 });
    }
  }, [startPosition]); // Depend on startPosition prop

  // Mouse down event to start dragging
  const handleMouseDown = (e) => {
    setDragging(true);
    setStartDragPosition(position); // Capture the start position of the drag
    e.preventDefault(); // Prevent text selection
  };

  // Mouse move event to handle dragging
  const handleMouseMove = (e) => {
    if (dragging) {
      if (startPosition === "left") {
        setPosition((prevPosition) => ({
          ...prevPosition,
          x: prevPosition.x + e.movementX, // Update position based on mouse movement
        }));
      } else {
        setPosition((prevPosition) => ({
          ...prevPosition,
          x: prevPosition.x - e.movementX, // Update position based on mouse movement
        }));
      }
    }
  };

  // Mouse up event to stop dragging and report the drag end
  const handleMouseUp = () => {
    setDragging(false);
    // Calculate the difference between start and end positions

    let diff = {
      x: position.x - startDragPosition.x,
      y: position.y - startDragPosition.y,
    };
    if (startPosition === "right") {
      diff = {
        x: startDragPosition.x - position.x,
        y: position.y - startDragPosition.y,
      };
    }
    // Call the onDragEnd prop with the difference
    onDragEnd(diff.x, startPosition);
    setPosition({ x: 0, y: 0 });
  };

  // Listen to mousemove and mouseup events on the document when dragging
  useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging, handleMouseMove, handleMouseUp]);

  // Inline styles for the draggable div

  let divStyle = {
    position: "absolute",

    left: `${position.x}px`,
    top: `${position.y}px`,
    cursor: dragging ? "grabbing" : "grab",
    zIndex: dragging ? 10 : 10,
    // Add other styles as needed
    height: "100%",
    width: "15px",
    background: "#007eff",
    display: "flex",
  };
  if (startPosition === "right") {
    divStyle = {
      position: "absolute",

      right: `${position.x}px`,
      top: `${position.y}px`,
      cursor: dragging ? "grabbing" : "grab",
      zIndex: dragging ? 10 : 10,
      // Add other styles as needed
      height: "100%",
      width: "15px",
      background: "#007eff",
      display: "flex",
    };
  }

  return (
    <div style={divStyle} onMouseDown={handleMouseDown}>
      <img
        src="/icons/arrow-left.svg"
        alt="arrow left"
        style={{
          alignSelf: "center",
          width: "100%",
          scale: "1.5",
          transform: startPosition === "right" ? "rotate(180deg)" : "",
        }}
      />
    </div>
  );
};

export default DraggableDiv;
