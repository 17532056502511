import { EditorContent } from "@tiptap/react";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import Breadcrumbs from "../../components/breadCrumbs";
import Comment from "../../containers/components/comment";
import InsertComment from "../../containers/components/insertComment";
import CurrentUserContext from "../../context/CurrentUserContext";
import { apiUrl } from "../../util/config";
import { DefaultEditor } from "./defaultEditor";

export default function MaterialViewer() {
  const [json, setJson] = useState({});
  const [author, setAuthor] = useState("");
  const [comments, setComments] = useState([]);

  const { id } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useContext(CurrentUserContext);


  // console.log(userContext);

  const resource = { id: id };
  const editor = DefaultEditor(false);

  useEffect(() => {
    const fetchMaterial = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/materials/${id}`);
        if (!response.ok) {
          if (editor) {
            editor.commands.setContent("<h1>Material not found</h1>");
          }
          return;
        }
        const data = await response.json();
        setAuthor(data.author);
        setJson(JSON.parse(data.data));
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchMaterial();
  }, [id, editor]);

  // Set content once JSON is fetched
  useEffect(() => {
    if (editor && json && Object.keys(json).length > 0) {
      editor.commands.setContent(json);
    }
  }, [editor, json]);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/comments-for-resource?resourceId=${resource.id}`,
          {
            withCredentials: true, // Include cookies in the request
          }
        );

        const commentsData = response.data;
        commentsData.sort(
          (b, a) => new Date(b.created_at) - new Date(a.created_at)
        );
        setComments(commentsData);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    fetchComments();
  }, [comments]);

  const handleNewComment = (newComment, replyTo = null) => {
    setComments((prevComments) => {
      // If it's a top-level comment (not a reply), simply add it to the list
      if (!replyTo) {
        return [newComment, ...prevComments];
      }
      // Otherwise, it's a reply, so find the parent comment and update it
      const updatedComments = JSON.parse(JSON.stringify(prevComments)); // Deep copy to avoid direct state mutation

      const updateReplies = (comments) => {
        return comments.map((comment) => {
          if (comment.id === replyTo) {
            // Found the parent comment, add the new reply
            return {
              ...comment,
              reply_of_children: [
                newComment,
                ...(comment.reply_of_children || []),
              ],
            };
          } else if (comment.reply_of_children) {
            // Recursively update nested replies
            return {
              ...comment,
              reply_of_children: updateReplies(comment.reply_of_children),
            };
          }
          return comment;
        });
      };

      return updateReplies(updatedComments);
    });
  };

  function printDiv() {
    const divElement = document.querySelector(".tiptap.ProseMirror");
    if (divElement) {
      const originalContent = document.body.innerHTML;
      document.body.innerHTML = divElement.outerHTML;
      window.print();
      document.body.innerHTML = originalContent;
    } else {
      console.log("Element not found");
    }
  }

  const handleCopyClick = () => {
    const newPath = location.pathname.replace(/\/[^\/]+$/, "/editor");
    navigate(newPath, {
      state: { content: json },
    });
  };

  const handleDeleteClick = async () => {
    if (!window.confirm("Are you sure you want to delete this material?")) {
      return;
    }
    await axios
      .delete(`${apiUrl}/api/materials/${id}`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {
          navigate("/materials");
        }
      })
      .catch((error) => {
        console.error("Error deleting material:", error);
      });
  };

  return (
    <>
      <Breadcrumbs />
      <div>
        <div className="viewer-bar">
          <span>
            {t("resource_details.author")}: {author}
          </span>
          <button className="make-new" onClick={handleCopyClick}>
            Copy
          </button>
          {currentUser.username === author && (
            <button
              className="make-new"
              onClick={() =>
                navigate(`${location.pathname}/edit`, {
                  state: { content: json },
                })
              }
            >
              Edit
            </button>
          )}
          <button className="make-new" onClick={printDiv}>
            Print
          </button>
          {currentUser.username === author && (
            <button className="make-new" onClick={handleDeleteClick}>
              Delete
            </button>
          )}
        </div>
        <div className="tiptap-editor">
          <EditorContent editor={editor} />
        </div>
        <div className="comment-section">
          <ul>
            {comments.reverse().map((comment) => (
              <Comment
                key={comment.id}
                showButtons={1 && false}
                comment={comment}
                resource_id={resource.id}
                onNewComment={handleNewComment}
                collapseChildren={true}
              />
            ))}
            <InsertComment
              reply_to={null}
              resource_id={resource.id}
              unfolded={true}
              always_unfolded={true}
              onNewComment={handleNewComment}
            />
          </ul>
        </div>
      </div>
    </>
  );
}
