import React from "react";
import { Link } from "react-router-dom";
import { apiUrl } from "../../util/config";
import { parseImagePath } from "../../util/parseImagePath";

const CategoryCard = ({ category }) => {
  return (
    <Link to={category.Name} className="category-card">
      <div className="overlay" />
      <img
        src={`${apiUrl}/${parseImagePath(category.image_path)}`}
        alt={`${category.Name}`}
      />
      <h4>{category.Name}</h4>
      <p>{category.description}</p>
      {/* <p>Sub categories: {category.subcategories}</p> */}
    </Link>
  );
};

export default CategoryCard;
