import { apiUrl } from "../../util/config";
import { parseImagePath } from "../../util/parseImagePath";

export default function MaterialBanner({ category }) {
  return (
    <div className="material-banner">
      <img
        src={`${apiUrl}/${parseImagePath(category.image_path)}`}
        alt={`${category.Name}`}
      />
      <h1>{category.Name}</h1>
    </div>
  );
}
