import axios from "axios";
import React, { useState } from "react";
import filledStarIcon from "../../../assets/icons/star-filled.svg";
import outlinedStarIcon from "../../../assets/icons/star-outlined.svg";
import emptyStarIcon from "../../../assets/icons/star.svg";
import { apiUrl } from "../../../util/config";

export default function RatingStars({ material }) {
  const [filledStars, setFilledStars] = useState(material.average_rating || 0);
  const [userRating, setUserRating] = useState(material.user_rating || 0);
  const [totalReviews, setTotalReviews] = useState(material.like_count || 0);

  const handleStarClick = async (starNumber) => {
    let newTotalReviews = totalReviews;
    let pastRatings = filledStars * totalReviews;

    if (userRating === 0) {
      newTotalReviews += 1;
      setTotalReviews(newTotalReviews);
    } else {
      pastRatings -= userRating;
    }
    const newAverageRating = (pastRatings + starNumber) / newTotalReviews;
    setUserRating(starNumber);
    setFilledStars(newAverageRating);

    await axios
      .post(
        `${apiUrl}/api/insert-rating`,
        {
          resourceId: material.id,
          ratingValue: starNumber,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (!response.status === 200) {
          console.error("Failed to submit rating");
        }
      })
      .catch((error) => {
        console.error("Error calling /api/insert-rating:", error);
      });
  };

  return (
    <div className="rating-stars-container">
      {[1, 2, 3, 4, 5].map((starNumber) => (
        <img
          key={starNumber}
          className="material-row-icon"
          src={
            starNumber <= userRating
              ? outlinedStarIcon
              : starNumber <= filledStars
              ? filledStarIcon
              : emptyStarIcon
          }
          alt="star"
          onClick={() => handleStarClick(starNumber)}
        />
      ))}
    </div>
  );
}
