import React from "react";

const ExpandIcon = ({ onClick }) => {
  return (
    <img
      src="/icons/arrows-diagonal.scg"
      alt="arrows diagonal"
      height={30}
      width={30}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    />
  );
};

export default ExpandIcon;
