import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = () => {
  const path = window.location.pathname;

  const toTitle = (text) => {
    return text.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  };

  const pathSegments = path.split("/").filter(Boolean).map(decodeURIComponent); // Split the path and remove any empty strings
  const breadcrumbLinks = pathSegments.map((segment, index, arr) => {
    const link = `/${arr.slice(0, index + 1).join("/")}`; // Create the link for this breadcrumb

    return (
      <React.Fragment key={index}>
        <Link to={link}>{toTitle(segment)}</Link>
        {index < arr.length - 1 && " > "}
      </React.Fragment>
    );
  });

  return (
    <div className="breadcrumbs">
      <Link to="/">Home</Link>
      {breadcrumbLinks.length > 0 && " > "}
      {breadcrumbLinks}
    </div>
  );
};

export default Breadcrumbs;
