import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import CurrentUserContext from "../../../context/CurrentUserContext";
import isAdmin from "../../../util/isAdmin";
import UpdateUserColorForm from "./changeColor";

export default function Profile() {
  const userContext = useContext(CurrentUserContext);
  const user = userContext.currentUser;

  const { t } = useTranslation();

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ maxWidth: "600px", margin: "0 auto", textAlign: "center" }}>
      <h2>{t("navigation.profile")}</h2>
      <p>
        {t("user.name")}: {`${user.firstname} ${user.lastname}`}
      </p>
      <p>
        {t("user.email")}: {user.email}
      </p>
      <UpdateUserColorForm
        initialColor={user.color}
        userID={userContext.currentUser.id}
      />
    </div>
  );
}
