import React from "react";

const EditorIcon2 = ({ onClick }) => {
  return (
    <img
      src="/icons/edit.svg"
      alt="edit"
      onClick={onClick}
      style={{
        cursor: "pointer",
        width: "200px",
        height: "200px",
      }}
    />
  );
};

export default EditorIcon2;
