import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

const SelectedAvailableBoxes = forwardRef(
  (
    {
      selected = [3],
      available = [1, 2],
      nameMap = new Map([
        [1, "bruh"],
        [2, "bruh2"],
        [3, "bruh3"],
      ]),
      selectedName,
      availableName,
      text,
    },
    ref
  ) => {
    const [availableList, setAvailableList] = useState(available);
    const [selectedList, setSelectedList] = useState(selected);

    useEffect(() => {
      setAvailableList(available);
    }, [available]);

    useEffect(() => {
      setSelectedList(selected);
    }, [selected]);

    const map = nameMap || new Map();

    const handleClick = (ID, add) => {
      let newAvailableList = add
        ? availableList.filter((item) => item !== ID)
        : availableList.concat(ID);
      let newSelectedList = add
        ? selectedList.concat(ID)
        : selectedList.filter((item) => item !== ID);

      setAvailableList(newAvailableList);
      setSelectedList(newSelectedList);
    };

    useImperativeHandle(ref, () => ({
      getSelectedIds: () => selectedList,
    }));

    return (
      <div className="flex" style={{ flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          <h2 style={{ flex: "1", textAlign: "center" }}>
            {availableName ? availableName : "Available Categories"}
          </h2>
          <h2 style={{ flex: "1", textAlign: "center" }}>
            {selectedName ? selectedName : "Selected Categories"}
          </h2>
        </div>
        {text && (
          <div>
            <p style={{ margin: "5px" }}>{text}</p>
          </div>
        )}

        <div style={{ display: "flex" }}>
          <div className="available-categories" style={{ flex: "1" }}>
            <div
              className="innerDiv"
              style={{ height: "100%", margin: "0px 5px 5px" }}
            >
              {availableList.map((id) => (
                <p
                  className="selection-item"
                  key={id}
                  onClick={() => handleClick(id, true)}
                >
                  {map.get(id)}
                </p>
              ))}
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              width={30}
              height={30}
              src="/icons/arrows-diff.svg"
              alt="arrows"
            />
          </div>

          <div className="selected-categories " style={{ flex: "1" }}>
            <div
              className="innerDiv"
              style={{ height: "100%", margin: "0px 5px 5px" }}
            >
              {selectedList.map((id) => (
                <p
                  className="selection-item"
                  key={id}
                  onClick={() => handleClick(id, false)}
                >
                  {map.get(id)}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default SelectedAvailableBoxes;
