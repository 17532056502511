import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./styles/footer.css";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <p>
        Pycipedia – {t("footer.footer_1")}
        <Link to="/about">www.pycipedia.se/about</Link>
        {t("footer.footer_2")}
      </p>
    </footer>
  );
}
