import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CurrentUserContext from "../context/CurrentUserContext";
import CustomEditor from "../custom_editor/CustomEditor";
import { apiUrl } from "../util/config";
import NewResourceComponent from "./components/newResource";
import axios from "axios";

const EditPostComponent = () => {
  const [resource, setResource] = useState(null);
  const [resourceMeta, setResourceMeta] = useState(null);
  const [loading, setLoading] = useState(true);
  const [permission, setPermission] = useState(false);

  const currentUser = useContext(CurrentUserContext);
  const { postID } = useParams();

  useEffect(() => {
    const fetchResourceDetails = async () => {
      setLoading(true);
      try {
        const resourceResponse = await axios.get(
          `${apiUrl}/api/resource/${postID}`,
          {
            withCredentials: true, // Include cookies in the request
          }
        );

        const resourceData = resourceResponse.data;
        if (currentUser.currentUser.id === resourceData.created_by) {
          setPermission(true);

          // Fetch resourceMeta if permission is granted
          const metaResponse = await axios.get(
            `${apiUrl}/api/resource-meta?resourceId=${postID}`,
            {
              withCredentials: true, // Include cookies in the request
            }
          );

          const metaData = metaResponse.data;
          setResourceMeta(metaData);
        } else {
          setPermission(false);
        }

        setResource(resourceData);
      } catch (error) {
        console.error("Error fetching resource details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchResourceDetails();
  }, [postID, currentUser.id, currentUser.currentUser.id]); // Adding currentUser.id to the dependency array

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!permission) {
    return <div>Permission denied.</div>;
  }

  // Assuming resourceMeta should not be null to proceed
  if (!resourceMeta) {
    return <div>Resource meta not found.</div>;
  }

  // Conditional rendering based on editor_used
  if (resource.editor_used === 3) {
    return (
      <CustomEditor resource={resource} givenResourceMeta={resourceMeta} />
    );
  }
  if (resource.editor_used === 2) {
    return (
      <NewResourceComponent
        postType={resource.postTypeInformation}
        resource={resource}
        resourceMeta={resourceMeta}
      />
    );
  }

  // Default rendering if none of the above conditions are met
  return (
    <div>
      <h2>Editing Resource: {resource.title}</h2>
      <p>{resource.description}</p>
      {/* Render additional UI elements for editing as needed */}
    </div>
  );
};

export default EditPostComponent;
