import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/breadCrumbs";
import { useCategories } from "../../context/categoryContext";
import AllMaterials from "./allMaterials";
import MaterialBanner from "./materialBanner";

export default function Subcategory() {
  const { t } = useTranslation();
  const { categories } = useCategories();
  const category = useParams().subcategory;

  return (
    <>
      <Breadcrumbs />
      <div className="subcategory-page">
        {category && categories.length > 0 && (
          <MaterialBanner
            category={categories.find((cat) => cat.Name === category)}
          />
        )}
        <Link to={"editor"} className="make-new">
          <span>+</span> {t("buttons.make_new")}
        </Link>
        {category && categories.length > 0 && (
          <AllMaterials
            categoryId={categories.find((cat) => cat.Name === category)}
          />
        )}
      </div>
    </>
  );
}
