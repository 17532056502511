import React, { useEffect, useState } from "react";

const GridIcon = ({ chosen, size = "50" }) => {
  const [hasGrid, setHasGrid] = useState(chosen);

  useEffect(() => {
    setHasGrid(chosen);
  }, [chosen]);

  const handleClick = () => {
    setHasGrid(!hasGrid);
    //onclick(!hasGrid);
  };

  return (
    <div className="grid-icon" onClick={handleClick}>
      {grid(hasGrid, size)}
    </div>
  );
};

const grid = (hasGrid, size) => {
  if (hasGrid) {
    return <img src="/icons/grid.svg" alt="grid" width={size} height={size} />;
  }
  return (
    <img src="/icons/no-grid.svg" alt="no grid" width={size} height={size} />
  );
};

export default GridIcon;
