import { EditorContent } from "@tiptap/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DefaultEditor } from "./defaultEditor";
import "./editor_page.css";
import ToolBar from "./toolbar";

const MaterialEditor = () => {
  const location = useLocation();
  const editor = DefaultEditor();

  useEffect(() => {
    if (location.state?.content) {
      editor.commands.setContent(location.state.content);
    }
  }, [editor]);

  return (
    <>
      <div className="editor-div-styling">
        <div className="tiptap-editor">
          <ToolBar editor={editor} />
          <EditorContent editor={editor} />
        </div>
      </div>
    </>
  );
};

export default MaterialEditor;
