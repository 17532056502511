import React, { useContext, useEffect, useState } from "react";
import CurrentUserContext from "../../../context/CurrentUserContext";
import { apiUrl } from "../../../util/config";
import isAdminOrCreator from "../../../util/isAdminOrCreator";
import Modal from "../general/modal";
import UserList from "../users/userList";
import UserSelector from "../users/UserSelector";

const ChangePrivacy = ({ isPrivate, permissions, resourceID, resource }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [permissionUsers, setPermissionUsers] = useState(
    permissions.permissionUsers
  );

  const [changed, setChanged] = useState(0);

  const userContext = useContext(CurrentUserContext);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/all-users-and-groups-details`,
          {
            method: "GET",
            credentials: "include", // to ensure cookies are sent
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setAllUsers(data.users);
      } catch (error) {
        console.error("Error:", error);
      }
    })();
  }, []);

  const handleRemoval = async (userId) => {
    try {
      const response = await fetch(`${apiUrl}/api/remove-resource-permission`, {
        method: "DELETE",
        credentials: "include", // to ensure cookies are sent
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ resourceID, userID: userId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        setChanged(changed + 1);
        setPermissionUsers((prevUsers) =>
          prevUsers.filter((user) => user.id !== userId)
        );
        //here
      }

      const result = await response.json();
      alert(result.message);
    } catch (error) {
      console.error("Error:", error);
      alert("Error removing user from resource permission.");
    }
  };

  const handleInsertion = async (userId, groupId = null, accessLevel = "") => {
    try {
      const response = await fetch(`${apiUrl}/api/insert-resource-permission`, {
        method: "POST",
        credentials: "include", // to ensure cookies are sent
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ resourceID, userId, groupId, accessLevel }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        const userToAdd = allUsers.find((user) => user.userId === userId);
        // If the user is found, add it to permissionUsers
        if (userToAdd) {
          setChanged(changed + 1);
          setPermissionUsers((prevUsers) => [...prevUsers, userToAdd]);
          //here
        } else {
        }
      }

      const result = await response.json();
      alert(result.message);
    } catch (error) {
      console.error("Error:", error);
      alert("Error inserting resource permission.");
    }
  };

  useEffect(() => {}, [isPrivate]);

  const handleDivClick = () => {
    setModalOpen(true);
  };

  useEffect(() => {}, [permissionUsers]);

  const AdminOrCreator = isAdminOrCreator(userContext, resource);

  return (
    <div>
      <button
        onClick={handleDivClick}
        style={{ display: "flex", alignItems: "center" }}
      >
        <span> Open Permissions </span>
        {isPrivate === 1 && (
          <img src="/icons/lock.svg" alt="lock" width={30} height={30} />
        )}
      </button>
      <Modal
        key={changed}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      >
        <div>
          <h3>Permissions</h3>
          {isAdminOrCreator(userContext, resource) ? (
            <UserSelector allUsers={allUsers} onHandle={handleInsertion} />
          ) : null}

          <UserList
            users={permissionUsers}
            groups={permissions.permissionGroups}
            Allusers={allUsers.users}
            displayConfig={{
              username: true,
              firstname: true,
              lastname: true,
              userRole: false,
              showActionButton: AdminOrCreator,
              actionButtonLabel: "Remove from permissions",
              modalContent: (user, actionInput, setActionInput) => (
                <>
                  <p>
                    Are you sure you want to delete {user.firstname}{" "}
                    {user.lastname} ({user.username}) from?
                  </p>
                </>
              ),
              actionConfirmButtonLabel: "Confirm removal",
              requireConfirmation: false,
            }}
            onAction={(user, input, closeModal) => {
              handleRemoval(user.id);

              closeModal();
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ChangePrivacy;
