import heartFilledIcon from "../assets/icons/heart-filled.svg";
import heartEmptyIcon from "../assets/icons/heart.svg";

export default function LikeButton({ isSaved, onToggle }) {
  const handleClick = () => {
    onToggle(!isSaved);
  };

  return (
    <img
      title="Save"
      onClick={handleClick}
      className="material-row-icon"
      src={isSaved ? heartFilledIcon : heartEmptyIcon}
      alt="heart"
      width={20}
    />
  );
}
