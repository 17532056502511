import { useTranslation } from "react-i18next";
import "./about_page.css";

export default function AboutPage() {
  const { t } = useTranslation();

  return (
    <div className="about">
      <h1>{t("about.title")}</h1>
      <p>{t("about.content_1")}</p>
      <p>{t("about.content_2")}</p>
      <p>
        {t("about.content_3")}
        <a href="https://socialcare.itu.dk">https://socialcare.itu.dk</a>
        {t("about.content_4")}
      </p>

      <p className="contact">
        {t("about.contact")}
        {<a href="mailto:erig@itu.dk">erig@itu.dk</a>}.
      </p>
    </div>
  );
}
