import React, { useEffect, useState } from "react";

// The CustomSVG component accepts a className for styling and degrees for rotation.
const PortraitLandscapeSVG = ({
  degrees = 0,
  onclick,
  chosen,
  size = "50",
}) => {
  // Inline style for rotating the SVG based on the degrees prop.
  const [isChosen, setIsChosen] = useState(chosen);
  useEffect(() => {
    setIsChosen(chosen);
  }, [chosen]);

  const rotationStyle = {
    transform: isChosen ? `rotate(${0}deg)` : `rotate(${degrees}deg)`,
    display: "inline-block",
    height: `${size}px`,
    width: `${size}px`,
    cursor: "pointer",
    //background: isChosen ? 'lightgrey' : 'white',
  };
  const handleClick = () => {
    setIsChosen(true);
    onclick();
  };

  return (
    <div style={rotationStyle} onClick={handleClick}>
      <img
        src="/icons/portrait-landscape.svg"
        alt="portrait landscape"
        width={size}
        height={size}
      />
    </div>
  );
};

export default PortraitLandscapeSVG;
