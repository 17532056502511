import axios from "axios";
import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import CategoryContainer from "./containers/CategoryContainer";
import InsertCategoryComponent from "./containers/components/categories/insertCategory";
import FileUploadAndGallery from "./containers/components/images/fileUploadAndGallery";
import JustTheResource from "./containers/components/micro_components/justTheResource";
import NewResourceComponent from "./containers/components/newResource";
import Profile from "./containers/components/profile/Profile";
import EditPostComponent from "./containers/EditPostComponent";
import ParentComponent from "./containers/postTypeMaker";
import ResourceDetails from "./containers/ResourceDetails";
import UsersAndGroupsContainer from "./containers/usersAndGroups";
import UsersToParents from "./containers/userToParent";
import { CategoryProvider } from "./context/categoryContext";
import CurrentUserContext from "./context/CurrentUserContext";
import PathsContext from "./context/PathsContext";
import Footer from "./footer";
import Header from "./header";
import AboutPage from "./pages/about_page/aboutPage";
import MaterialEditor from "./pages/editor_page/materialEditor";
import MaterialViewer from "./pages/editor_page/materialViewer";
import ForumPage from "./pages/forum_page/forumPage";
import ForumPost from "./pages/forum_page/forumPost";
import HomePage from "./pages/homePage";
import Login from "./pages/login_page/loginPage";
import MaterialsPage from "./pages/materials_page/materialsPage";
import Subcategory from "./pages/materials_page/subcategory";
import ProfilePage from "./pages/profile_page/profilePage";
import "./styles/css.css";
import { apiUrl } from "./util/config";
import isAdmin from "./util/isAdmin";

const generateRoutes = (categories, basePath = "", type) => {
  let routes = [];
  categories.forEach((category) => {
    const currentPath = `${basePath}/${category.Name}`;
    routes.push(
      <Route
        key={category.ID}
        path={currentPath}
        element={
          <CategoryContainer
            category={category}
            currentPath={currentPath}
            postType={type}
          />
        }
      />
    );
    category.resources.forEach(
      (resource) => (resource.categoryName = category.Name)
    );

    category.resources.forEach((resource) => {
      const newpath = `${currentPath}/${resource.id}`.replace(/ /g, "_");

      routes.push(
        <Route
          path={newpath}
          element={
            <ResourceDetails
              resource={resource}
              displayConfig={type.displayConfig}
            />
          }
        />
      );

      routes.push(
        //just the resource
        <Route
          path={`/resource${resource.id}`}
          element={<JustTheResource resource={resource} />}
        />
      );
    });
    if (category.subcategories.length > 0) {
      routes = routes.concat(
        generateRoutes(category.subcategories, currentPath, type)
      );
    }
  });

  return routes;
};

const generateResourcePathsMap = (categories, basePath = "", type) => {
  let pathsMap = new Map();

  const generatePaths = (categories, basePath) => {
    categories.forEach((category) => {
      const currentPath = `${basePath}/${category.Name}`;

      // Process resources of the current category
      category.resources.forEach((resource) => {
        const newPath = `${currentPath}/${resource.id}`.replace(/ /g, "_");

        pathsMap.set(resource.id, newPath);
      });

      // Process subcategories
      if (category.subcategories.length > 0) {
        generatePaths(category.subcategories, currentPath);
      }
    });
  };

  generatePaths(categories, basePath);
  return pathsMap;
};

const generateAllResourcePaths = (categoriesData, idToType) => {
  let allPathsMap = new Map();

  Object.keys(categoriesData).forEach((type) => {
    const basePath = `/${idToType[type].name}`;
    const pathsMap = generateResourcePathsMap(
      categoriesData[type],
      basePath,
      idToType[type]
    );

    // Merge current pathsMap into allPathsMap
    pathsMap.forEach((value, key) => {
      allPathsMap.set(key, value);
    });
  });

  return allPathsMap;
};

function App() {
  const [categoriesData, setCategoriesData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [postTypes, setPostTypes] = useState([]); // New state variable
  const [idToType, setidToType] = useState({}); // New state variable
  const [allPaths, setAllPaths] = useState({}); // New state variable
  const [currentUser, setCurrentUser] = useState({}); // New state variable

  const fetchCategoriesByType = async (postType) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/categories?postType=${postType}`,
        { withCredentials: true }
      );
      if (!response.status === 200) {
        throw new Error("Network response was not ok");
      }

      return response.data;
    } catch (err) {
      throw err;
    }
  };

  const fetchDisplayConfigByType = async (postType) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/post-display-config/${postType}`,
        { withCredentials: true }
      );
      if (!response.status === 200) {
        throw new Error("Network response was not ok");
      }

      return response.data;
    } catch (err) {
      throw err;
    }
  };

  const fetchResourceTypesAndCategories = async () => {
    try {
      const resTypesResponse = await axios.get(
        `${apiUrl}/api/all-resource-types`,
        { withCredentials: true }
      );

      if (!resTypesResponse.status === 200) {
        throw new Error("Error fetching resource types");
      }
      const resTypesData = resTypesResponse.data;

      let categoriesByType = {};
      let types = {};
      for (const type of resTypesData.data) {
        const categories = await fetchCategoriesByType(type.id);
        categoriesByType[type.id] = categories;
        type.displayConfig = (await fetchDisplayConfigByType(type.id)).data;
        types[type.id] = type;
      }
      setidToType(types);
      setPostTypes(resTypesData.data);
      setCategoriesData(categoriesByType);
      setLoading(false);

      const allPathsMap = generateAllResourcePaths(categoriesByType, types);
      setAllPaths(allPathsMap);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      fetchResourceTypesAndCategories();
    } catch (error) {}
  }, []);

  const fetchUserInformation = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/get-user-details`, {
        withCredentials: true,
      });

      if (!response.data.username) {
        return;
      }

      return response;
    } catch (err) {
      console.error("Error fetching user information:", err);
    }
  };

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const data = await fetchUserInformation();
        if (!data) {
          return;
        }
        setCurrentUser(data.data);
      } catch (error) {
        console.error("Error fetching user information:", error);
      }
    };

    getUserInfo();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    console.error("Error:", error);
    return <Login />;
  }

  if (!currentUser || Object.keys(currentUser).length === 0) {
    return <Login />;
  }
  return (
    <PathsContext.Provider value={{ allPaths, setAllPaths }}>
      <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
        <CategoryProvider>
          <Router>
            <Header />
            <main>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<HomePage />} />
                <Route path="/my_page" element={<ProfilePage />} />
                <Route path="/materials" element={<MaterialsPage />} />
                <Route
                  path="/materials/:category"
                  element={<MaterialsPage />}
                />
                <Route
                  path="/materials/:category/:subcategory"
                  element={<Subcategory />}
                />
                <Route
                  path="/materials/:category/:subcategory/editor"
                  element={<MaterialEditor />}
                />
                <Route
                  path="/materials/:category/:subcategory/:id"
                  element={<MaterialViewer />}
                />
                <Route
                  path="/materials/:category/:subcategory/:id/edit"
                  element={<MaterialEditor />}
                />
                <Route path="/newforum" element={<ForumPage />} />
                <Route path="/newforum/:id" element={<ForumPost />} />
                <Route
                  path="/image_bank"
                  element={
                    <FileUploadAndGallery
                      displayConfig={{ CanBeSelected: false }}
                    />
                  }
                />
                <Route path="/about" element={<AboutPage />} />
                {isAdmin(currentUser) && (
                  <>
                    <Route
                      path="/admin/users"
                      element={<UsersAndGroupsContainer />}
                    />
                    <Route path="/admin/types" element={<ParentComponent />} />
                    <Route
                      path="/admin/categories"
                      element={<InsertCategoryComponent />}
                    />

                    <Route
                      path="/admin/user_parents"
                      element={<UsersToParents />}
                    />
                  </>
                )}
                <Route path="/profile" element={<Profile />} />
                <Route path="/*" element={<Login />} />

                <Route
                  path="/editing/:postID"
                  element={<EditPostComponent />}
                />
                {Object.keys(categoriesData).map((type) => {
                  // Base path for each resource type
                  const basePath = `/${idToType[type].name}`;
                  return (
                    <React.Fragment key={type}>
                      <Route
                        path={basePath}
                        element={
                          <CategoryContainer
                            categories={categoriesData[type]}
                            postType={idToType[type]}
                            currentPath={`/${idToType[type].name}`}
                          />
                        }
                      />
                      {generateRoutes(
                        categoriesData[type],
                        basePath,
                        idToType[type]
                      )}
                    </React.Fragment>
                  );
                })}
                {postTypes.map((type) => {
                  const basePath = `/add_new/${type.id}`;
                  return (
                    <Route
                      path={basePath}
                      element={<NewResourceComponent postType={type} />}
                    />
                  );
                })}
              </Routes>
            </main>

            <Footer />
          </Router>
        </CategoryProvider>
      </CurrentUserContext.Provider>
    </PathsContext.Provider>
  );
}

export default App;
