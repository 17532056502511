import axios from "axios";
import React, { useState } from "react";
import { apiUrl } from "../../util/config";
import ProfilePage from "../profile_page/profilePage";
import "./login_page.css";

function Login() {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [IsLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${apiUrl}/api/set-cookie`,
        {
          identifier,
          password,
        },
        {
          withCredentials: true,
        }
      );
      setMessage(response.data.message);
      if (response.status === 200) {
        checkCookie();
        window.location.reload();
      } else {
        setMessage("An error occurred during the login process.");
      }
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data);
      } else {
        setMessage("An error occurred during the login process.");
      }
    }
  };

  const checkCookie = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/check-cookie`, {
        withCredentials: true,
      });

      if (response.data.loggedIn) {
        setIsLoggedIn(true);
        setMessage(`Welcome, ${response.data.identifier}`);
      } else {
        setMessage(response.data.message || "Not logged in");
      }
    } catch (error) {
      console.error(
        "Error checking cookie:",
        error.response ? error.response.data : error.message
      );
      setMessage("Failed to check cookie.");
    }
  };

  if (IsLoggedIn) {
    return <ProfilePage />;
  }

  return (
    <div className="login-page">
      <h1>Pycipedia</h1>
      <h2>Login</h2>
      <form onSubmit={handleLogin} className="login-form">
        <input
          type="text"
          value={identifier}
          onChange={(e) => setIdentifier(e.target.value)}
          placeholder="Username or Email"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          label="Password"
          placeholder="Password"
        />
        <button type="submit">Login</button>
        <p>{message}</p>
      </form>
    </div>
  );
}

export default Login;
