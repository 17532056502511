import React, { useEffect, useRef, useState } from "react";
import { apiUrl } from "../../../util/config";
import SelectedAvailableBoxes from "../general/SelectedAvailableBoxes";
import Modal from "../general/modal";

const AddParentToResourceButton = ({ sosuUserId, resourceId }) => {
  const [parentUsers, setParentUsers] = useState([]);
  const [resourceUsers, setResourceUsers] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasChanged, setHasChanged] = useState(false); // This is a boolean that will be set to true when the user has made changes to the selected users
  const [map, setMap] = useState(new Map());

  useEffect(() => {
    setLoading(true);
    if (sosuUserId) {
      fetch(`${apiUrl}/api/get-parent-users`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add authentication headers/token as needed
        },
        body: JSON.stringify({ sosuUserId: sosuUserId }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch parent users");
          }
          return response.json();
        })
        .then((data) => {
          setParentUsers(data);
          setMap(new Map(data.map((user) => [user.ID, user.username])));
        })
        .catch((err) => setError(err.message));
    }

    if (resourceId) {
      const queryParams = new URLSearchParams({ resourceId }).toString();
      fetch(`${apiUrl}/api/get-users-for-resource?${queryParams}`, {
        credentials: "include",
        method: "GET",
        headers: {
          // Add authentication headers/token as needed
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch users for resource");
          }
          setLoading(false);
          return response.json();
        })
        .then((data) => setResourceUsers(data.users)) // Assuming the API returns an object with a users array
        .catch((err) => setError(err.message));
    }
  }, [sosuUserId, resourceId]); // Re-fetch when these IDs change

  useEffect(() => {}, [
    parentUsers,
    resourceUsers,
    isModalOpen,
    resourceId,
    sosuUserId,
  ]);

  const boxRef = useRef();

  const handleConfirm = () => {
    setModalOpen(false);
    addResourceToUsers();
  };

  const addResourceToUsers = () => {
    const selectedIDs = boxRef.current.getSelectedIds();
    fetch(`${apiUrl}/api/add-resource-to-users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Include other headers as needed, such as Authorization if your API requires it
      },
      body: JSON.stringify({
        resourceId: resourceId,
        userIds: selectedIDs,
      }),
    })
      .then((response) => {
        setResourceUsers(
          parentUsers.filter((user) => selectedIDs.includes(user.ID))
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        alert("Resource added to users successfully");
        return response.json();
      })
      .then((data) => {})
      .catch((err) => {
        console.error("Error adding resource to users:", err);
        setError("An error occurred while adding the resource to the users.");
      });
  };

  return (
    <>
      <button className="new-button" onClick={() => setModalOpen(true)}>
        <img
          src="/icons/user-plus.svg"
          alt="add parent user"
          width={30}
          height={30}
        />
        <p style={{ color: "black", margin: "0px" }}>Share parents</p>
      </button>

      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        {!loading && (
          <SelectedAvailableBoxes
            ref={boxRef}
            available={parentUsers
              .filter(
                (user) => !resourceUsers.some((Ruser) => user.ID == Ruser.ID)
              )
              .map((user) => user.ID)}
            selected={resourceUsers.map((user) => user.ID)}
            nameMap={map}
            selectedName={"Parents with resource"}
            availableName={"Parents without resource"}
            text={"Select or deselect parents to add to the resource"}
          />
        )}
        <button onClick={handleConfirm}>Confirm Changes</button>
        <button
          style={{ background: "red" }}
          onClick={() => setModalOpen(false)}
        >
          Cancel
        </button>
      </Modal>
    </>
  );
};

export default AddParentToResourceButton;
