import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CurrentUserContext from "../../context/CurrentUserContext";
import { apiUrl } from "../../util/config";
import MaterialRow from "./material_row/materialRow";

export default function AllMaterials({ categoryId }) {
  const [materials, setMaterials] = useState([]);

  const { t } = useTranslation();
  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${apiUrl}/api/all-materials-info`, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          if (!response.status === 200) {
            console.error("Failed to fetch materials");
          }
          const data = response.data;

          const filteredMaterials = categoryId.ID
            ? data.filter((material) => material.category_id === categoryId.ID)
            : data;

          const sortedAndFilteredMaterials = filteredMaterials
            .filter(
              (material) =>
                material.is_published === "true" ||
                material.created_by === currentUser.username
            )
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

          setMaterials(sortedAndFilteredMaterials);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    };
    fetchData();
  }, [categoryId]);

  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th>{t("resource_details.title")}</th>
          <th>{t("resource_details.category")}</th>
          <th>{t("resource_details.author")}</th>
          <th>{t("resource_details.date")}</th>
          <th>{t("resource_details.rating")}</th>
          <th>{t("resource_details.comments")}</th>
          <th>{t("resource_details.status")}</th>
          <th>{t("resource_details.save")}</th>
        </tr>
      </thead>
      <tbody>
        {materials &&
          materials.map((material) => (
            <MaterialRow material={material} key={material.id} />
          ))}
      </tbody>
    </table>
  );
}
