import axios from "axios";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import aligncenterIcon from "../../assets/icons/align-center.svg";
import alignleftIcon from "../../assets/icons/align-left.svg";
import alignrightIcon from "../../assets/icons/align-right.svg";
import boldIcon from "../../assets/icons/bold.svg";
import borderIcon from "../../assets/icons/border-all.svg";
import borderNoneIcon from "../../assets/icons/border-none.svg";
import photoIcon from "../../assets/icons/image.svg";
import italicIcon from "../../assets/icons/italic.svg";
import listnumbers from "../../assets/icons/list-numbers.svg";
import listIcon from "../../assets/icons/list.svg";
import publishIcon from "../../assets/icons/publish.svg";
import redoIcon from "../../assets/icons/redo.svg";
import saveIcon from "../../assets/icons/save.svg";
import tableIcon from "../../assets/icons/table.svg";
import underlineIcon from "../../assets/icons/underline.svg";
import undoIcon from "../../assets/icons/undo.svg";
import videoIcon from "../../assets/icons/video.svg";
import Modal from "../../containers/components/general/modal";
import FileUploadAndGallery from "../../containers/components/images/fileUploadAndGallery";
import { useCategories } from "../../context/categoryContext";
import { apiUrl } from "../../util/config";
import TableOptionsMenu from "./tableOptionsMenu";

const ToolBar = ({ editor }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isPictureModalOpen, setPictureModalOpen] = useState(false);
  const [isBorderVisible, setBorderVisible] = useState(false);

  const { categories } = useCategories();
  const category = useParams().subcategory;

  const openPictureModal = () => setPictureModalOpen(true);
  const closePictureModal = () => setPictureModalOpen(false);

  const handleTextSizeChange = (event) => {
    const level = parseInt(event.target.value, 10);
    if (level === 0) {
      editor.chain().focus().setParagraph().run();
    } else {
      editor.chain().focus().toggleHeading({ level }).run();
    }
  };

  const addImage = (image) => {
    const normalizedPath = image.path.replace(/\\/g, "/");
    const url = `${apiUrl}/` + normalizedPath;
    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
    closePictureModal();
  };

  if (!editor) {
    return null;
  }

  const addYoutubeVideo = () => {
    const url = prompt("Enter YouTube URL");

    if (url) {
      editor.commands.setYoutubeVideo({
        src: url,
        width: 640,
        height: 480,
      });
    }
  };

  const parseTitle = () => {
    const title = document.querySelector(".tiptap h1");
    if (title) {
      return title.textContent.trim();
    }
    alert("Document must have a title");
    return;
  };

  const overwrite = async (isPublished) => {
    const id = location.pathname.split("/").slice(-2, -1)[0];
    const title = parseTitle();
    if (!title) {
      return;
    }
    const message = isPublished
      ? `${title}\n\nAre you sure you want to publish this?`
      : `${title}\n\nAre you sure you want to save this as a draft? `;
    if (!window.confirm(message)) {
      return;
    }

    const categoryId = categories.find((cat) => cat.Name === category).ID;
    const data = JSON.stringify(editor.getJSON());
    const blob = new Blob([data], { type: "application/octet-stream" });

    const formData = new FormData();
    formData.append("category_id", categoryId);
    formData.append("title", title);
    formData.append("is_published", isPublished);
    formData.append("data", blob);

    axios
      .put(`${apiUrl}/api/materials/${id}`, formData, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {
          if (isPublished) {
            alert("Document published successfully");
          } else {
            alert("Document saved successfully");
          }
          navigate("/materials");
        } else {
          alert("Failed to save document");
        }
      })
      .catch((error) => {
        console.error("Error calling /api/materials:", error);
      });
  };

  const save = async (isPublished) => {
    const title = parseTitle();
    if (!title) {
      return;
    }
    const message = isPublished
      ? `${title}\n\nAre you sure you want to publish this?`
      : `${title}\n\nAre you sure you want to save this as a draft? `;
    if (!window.confirm(message)) {
      return;
    }

    const categoryId = categories.find((cat) => cat.Name === category).ID;
    const data = JSON.stringify(editor.getJSON());
    const blob = new Blob([data], { type: "application/octet-stream" });

    const formData = new FormData();
    formData.append("category_id", categoryId);
    formData.append("title", title);
    formData.append("is_published", isPublished);
    formData.append("data", blob);

    axios
      .post(`${apiUrl}/api/materials`, formData, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {
          if (isPublished) {
            alert("Document published successfully");
          } else {
            alert("Document saved successfully");
          }
          navigate("/materials");
        } else {
          alert("Failed to save document");
        }
      })
      .catch((error) => {
        console.error("Error calling /api/materials:", error);
      });
  };

  return (
    <div>
      {isPictureModalOpen && (
        <Modal isOpen={isPictureModalOpen} onClose={closePictureModal}>
          <FileUploadAndGallery
            onImageSelect={addImage}
            displayConfig={{ CanBeSelected: true }}
          />
        </Modal>
      )}

      <div className="toolbar">
        <img
          src={publishIcon}
          alt="Publish"
          onClick={() => {
            location.pathname.endsWith("/editor")
              ? save(true)
              : overwrite(true);
          }}
          title="Publish"
        />
        <img
          src={saveIcon}
          alt="Save"
          onClick={() => {
            location.pathname.endsWith("/editor")
              ? save(false)
              : overwrite(false);
          }}
          title="Save as a draft"
        />

        <img
          onClick={() => editor.chain().focus().undo().run()}
          src={undoIcon}
          alt="Undo"
          title="Undo"
        />
        <img
          onClick={() => editor.chain().focus().redo().run()}
          src={redoIcon}
          alt="Redo"
          title="Redo"
        />
        <div className="custom-select">
          <select
            className={
              editor.isActive("heading", { level: 1 }) ? "not-available" : ""
            }
            onChange={handleTextSizeChange}
            value={"Text Size"}
            disabled={editor.isActive("heading", { level: 1 })}
          >
            <option value="Text Size" disabled hidden>
              Text Size
            </option>
            <>
              <option value="2">Subtitle</option>
              <option value="0">Normal</option>
            </>
          </select>
        </div>
        <img
          className={editor.isActive("bold") ? "is-active" : ""}
          onClick={() => editor.chain().focus().toggleBold().run()}
          src={boldIcon}
          alt="Bold"
          title="Bold"
        ></img>
        <img
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is-active" : ""}
          src={italicIcon}
          alt="Italic"
          title="Italic"
        ></img>
        <img
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={editor.isActive("underline") ? "is-active" : ""}
          src={underlineIcon}
          alt="Underline"
          title="Underline"
        ></img>
        <img
          onClick={openPictureModal}
          src={photoIcon}
          alt="Add Image"
          title="Add Image"
        ></img>
        <img
          onClick={addYoutubeVideo}
          src={videoIcon}
          alt="Add Video"
          title="Add Video"
        ></img>
        <img
          className={editor.isActive("table") ? "is-active" : ""}
          onClick={() => {
            editor
              .chain()
              .focus()
              .enter()
              .enter()
              .selectNodeBackward()
              .insertTable({ rows: 1, cols: 2, withHeaderRow: false })
              .run();
          }}
          src={tableIcon}
          alt="Add Table"
          title="Add Table"
        />
        <img
          onClick={() => {
            setBorderVisible(!isBorderVisible);
          }}
          src={isBorderVisible ? borderIcon : borderNoneIcon}
          alt={isBorderVisible ? "Hide Table Borders" : "Show Table Borders"}
          title={isBorderVisible ? "Hide Table Borders" : "Show Table Borders"}
        />
        <img
          onClick={() => {
            editor.chain().focus().toggleBulletList().run();
          }}
          className={editor.isActive("bulletList") ? "is-active" : ""}
          src={listIcon}
          alt="Bullet List"
          title="Bullet List"
        ></img>
        <img
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
          src={listnumbers}
          alt="Numbered List"
          title="Numbered List"
        ></img>

        <img
          onClick={() => editor.chain().focus().setTextAlign("left").run()}
          className={`alignment-icon ${
            editor.isActive({ textAlign: "left" }) ? "is-active" : ""
          }`}
          src={alignleftIcon}
          alt="Left Align"
          title="Left Align"
        ></img>
        <img
          onClick={() => editor.chain().focus().setTextAlign("center").run()}
          className={
            editor.isActive({ textAlign: "center" }) ? "is-active" : ""
          }
          src={aligncenterIcon}
          alt="Center Align"
          title="Center Align"
        ></img>
        <img
          onClick={() => editor.chain().focus().setTextAlign("right").run()}
          className={editor.isActive({ textAlign: "right" }) ? "is-active" : ""}
          src={alignrightIcon}
          alt="Right Align"
          title="Right Align"
        ></img>
      </div>
      {editor.isActive("table") && <TableOptionsMenu editor={editor} />}
    </div>
  );
};

export default ToolBar;
