import { React, useState } from "react";
import { Link, useParams } from "react-router-dom";
import HeartIcon from "../../../components/likeButton";
import { useCategories } from "../../../context/categoryContext";
import RatingStars from "./ratingStars";

import axios from "axios";
import { apiUrl } from "../../../util/config";

export default function MaterialRow({ material }) {
  const { categories } = useCategories();

  const [userHasSaved, setUserHasSaved] = useState(material.user_saved);
  const materialCategory = categories.find(
    (cat) => cat.ID === material.category_id
  ).Name;

  const handleHeartToggle = async (newSavedStatus) => {
    setUserHasSaved(newSavedStatus);

    await axios
      .post(
        `${apiUrl}/api/save-resource`,
        {
          resourceId: material.id,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (!response.status === 200) {
          console.error("Failed to save resource for user.");
        }
      })
      .catch((error) => {
        console.error("Error calling /api/save-resource:", error);
      });
  };

  return (
    <tr>
      <td>
        <Link to={`${material.id}`}>{material.title}</Link>
      </td>
      <td>{materialCategory}</td>
      <td>{material.created_by}</td>
      <td>{new Date(material.created_at).toLocaleDateString()}</td>
      <td>
        <RatingStars material={material} />
      </td>
      <td>{material.comment_count}</td>
      <td>{material.is_published === "true" ? "Published" : "Draft"}</td>
      <td>
        <HeartIcon isSaved={userHasSaved} onToggle={handleHeartToggle} />
      </td>
    </tr>
  );
}
