const isAdmin = (input) => {
  let user = null;

  if (input && input.role) {
    user = input;
  } else if (input && input.currentUser && input.currentUser.role) {
    user = input.currentUser;
  }

  if (user && user.role && user.role.roleName === "ADMIN") {
    return true;
  }
  return false;
};

export default isAdmin;
