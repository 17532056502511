import addColumLeftIcon from "../../assets/icons/table/column-insert-left.svg";
import addColumRightIcon from "../../assets/icons/table/column-insert-right.svg";
import removeColumnIcon from "../../assets/icons/table/column-remove.svg";
import addRowAboveIcon from "../../assets/icons/table/row-insert-bottom.svg";
import addRowBelowIcon from "../../assets/icons/table/row-insert-top.svg";
import removeRowIcon from "../../assets/icons/table/row-remove.svg";
import removeTableIcon from "../../assets/icons/table/table-minus.svg";
import addTableIcon from "../../assets/icons/table/table-plus.svg";

export default function TableOptionsMenu({ editor }) {
  return (
    <div className="table-options-menu">
      <div>
        <img
          onClick={() => {
            editor
              .chain()
              .focus()
              .enter()
              .enter()
              .selectNodeBackward()
              .insertTable({ rows: 1, cols: 2, withHeaderRow: false })
              .run();
          }}
          src={addTableIcon}
          alt="Add Table"
          title="Add Table"
        />
        <img
          className={editor.isActive("table") ? "" : "disabled"}
          onClick={() => editor.chain().focus().addColumnBefore().run()}
          src={addColumLeftIcon}
          alt="Add Column Left"
          title="Add Column Left"
        />
        <img
          className={editor.isActive("table") ? "" : "disabled"}
          onClick={() => editor.chain().focus().addColumnAfter().run()}
          src={addColumRightIcon}
          alt="Add Column Right"
          title="Add Column Right"
        />
        <img
          className={editor.isActive("table") ? "" : "disabled"}
          onClick={() => editor.chain().focus().deleteColumn().run()}
          src={removeColumnIcon}
          alt="Remove Column"
          title="Remove Column"
        />
        <img
          className={editor.isActive("table") ? "" : "disabled"}
          onClick={() => {
            editor.chain().focus().deleteTable().run();
          }}
          src={removeTableIcon}
          alt="Remove Table"
          title="Remove Table"
        />
        <img
          className={editor.isActive("table") ? "" : "disabled"}
          onClick={() => editor.chain().focus().addRowBefore().run()}
          src={addRowAboveIcon}
          alt="Add Row Above"
          title="Add Row Above"
        />
        <img
          className={editor.isActive("table") ? "" : "disabled"}
          onClick={() => editor.chain().focus().addRowAfter().run()}
          src={addRowBelowIcon}
          alt="Add Row Below"
          title="Add Row Below"
        />
        <img
          className={editor.isActive("table") ? "" : "disabled"}
          onClick={() => editor.chain().focus().deleteRow().run()}
          src={removeRowIcon}
          alt="Remove Row"
          title="Remove Row"
        />
      </div>
    </div>
  );
}
