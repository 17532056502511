import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/breadCrumbs";
import { useCategories } from "../../context/categoryContext";
import CategoryCard from "./categoryCard";
import MaterialBanner from "./materialBanner";
import "./materials_page.css";

export default function MaterialsPage() {
  const { categories } = useCategories();
  const { category } = useParams();

  const parseCategories = () => {
    // If a category is selected, return only the subcategories of that category
    if (category) {
      return categories.filter(
        (subCategory) =>
          subCategory.sub_category_of ===
          categories.find((cat) => cat.Name === category).ID
      );
    }
    return categories.filter((category) => category.sub_category_of === null);
  };

  const countSubcategories = (category) => {
    return categories.filter(
      (subCategory) => subCategory.sub_category_of === category.ID
    ).length;
  };

  return (
    <>
      <Breadcrumbs />
      <div className="materials-page">
        {category && categories.length > 0 && (
          <MaterialBanner
            category={categories.find((cat) => cat.Name === category)}
          />
        )}
        <div className="category-cards">
          {categories &&
            parseCategories().map(
              (category, id) => (
                (category.subcategories = countSubcategories(category)),
                (<CategoryCard key={id} category={category} />)
              )
            )}
        </div>
      </div>
    </>
  );
}
