//Redundant function, not used in the code
const getAllChildrenBetter = (meta, position) => {
  let number_of_children = meta[position].number_of_children;
  let children = [];
  let highestChild = children[children.length - 1] + 1 || position + 1;

  while (number_of_children !== 0) {
    if (number_of_children > 0) {
      children.push(highestChild);
      children = children.concat(getAllChildrenBetter(meta, highestChild));
      number_of_children--;
      highestChild = children[children.length - 1] + 1;
    }
  }

  return children;
};
/*
const fixMeta = (meta, position) => {

    let children = getAllChildrenBetter(meta, position)
    
    let positionCounter = 0
    for (let i = children[children.length-1]; i < meta.length; i++) {
        if (meta[i].instruction != "ELEMENT") {
            positionCounter = i
            break
        }

    }
    meta.splice(position+1, positionCounter-position)

    consolelog("META", meta)
    return meta


} 
 */

export default getAllChildrenBetter;
