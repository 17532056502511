import React from "react";

const EditorIcon1 = ({ onClick }) => {
  return (
    <img
      src="/icons/upload.svg"
      alt="upload"
      onClick={onClick}
      style={{
        cursor: "pointer",
        width: "200px",
        height: "200px",
      }}
    />
  );
};

export default EditorIcon1;
