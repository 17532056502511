const selectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.05) translateY(-1px)",
    },
  }),

  indicatorSeparator: () => ({
    display: "none",
  }),

  dropdownIndicator: () => ({
    display: "none",
  }),

  menu: (provided) => ({
    ...provided,
    textAlign: "center",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#d3d3d3" : provided.backgroundColor,
    color: "#333",
    "&:hover": {
      backgroundColor: state.isSelected
        ? "rgba(0, 0, 0, 0.3)"
        : "rgba(0, 0, 0, 0.1)",
    },
  }),
};

export default selectStyles;
