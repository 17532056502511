import React from "react";

const EditorIcon3 = ({ onClick }) => {
  return (
    <img
      src="/icons/advanced-editor.svg"
      alt="advanced editor"
      onClick={onClick}
      style={{
        cursor: "pointer",
        width: "200px",
        height: "200px",
      }}
    />
  );
};

export default EditorIcon3;
