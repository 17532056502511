import { useTranslation } from "react-i18next";

export default function ForumPage() {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <h1>Forum</h1>
        <button>{t("general.buttons.make_new")}</button>
      </div>
      <table className="styled-table">
        <thead>
          <tr>
            <th>{t("resource_details.title")}</th>
            <th>{t("resource_details.category")}</th>
            <th>{t("resource_details.author")}</th>
            <th>{t("resource_details.date")}</th>
            <th>{t("resource_details.comments")}</th>
            <th>{t("resource_details.status")}</th>
            <th>{t("resource_details.save")}</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  );
}
