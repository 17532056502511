import axios from "axios";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import Link from react-router-dom for navigation
import Select from "react-select";
import NameInitialsAvatar from "./containers/components/micro_components/NameInitialsAvatar";
import CurrentUserContext from "./context/CurrentUserContext";
import "./styles/header.css";
import selectStyles from "./styles/selectStyles";
import { apiUrl } from "./util/config";
import isAdmin from "./util/isAdmin";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const userContext = useContext(CurrentUserContext);
  const location = useLocation();

  const languageOptions = [
    { value: "en", label: "ENG" },
    { value: "sv", label: "SWE" },
    { value: "no", label: "NOR" },
  ];

  const { t, i18n } = useTranslation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/logout`,
        {},
        { withCredentials: true }
      );

      if (response.status === 200) {
        navigate("/");

        window.location.reload();
      } else {
        console.error("Logout failed:", response.data.message);
      }
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  const parseLanguage = () => {
    let language = localStorage.getItem("language");
    if (!language) {
      language = navigator.language.split("-")[0];
      localStorage.setItem("language", language);
    }
    if (language == "sv") {
      return "SWE";
    } else if (language == "no") {
      return "NOR";
    }
    return "ENG";
  };

  const isActive = (path) => location.pathname.startsWith(path);

  return (
    <>
      <header>
        <div
          className={`overlay ${isMenuOpen ? "visible" : ""}`}
          onClick={toggleMenu}
        />
        <AdminMenu toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
        <Link to="/">
          <h1>Pycipedia</h1>
        </Link>
        <nav>
          <Link
            to="/my_page"
            className={`link ${isActive("/my_page") ? "active" : ""}`}
          >
            {t("navigation.my_page")}
          </Link>
          <Link
            to="/materials"
            className={`link ${isActive("/materials") ? "active" : ""}`}
          >
            {t("navigation.materials")}
          </Link>
          <Link
            to="/forum"
            className={`link ${isActive("/forum") ? "active" : ""}`}
          >
            {t("navigation.forum")}
          </Link>
          <Link
            to="/image_bank"
            className={`link ${isActive("/image_bank") ? "active" : ""}`}
          >
            {t("navigation.image_bank")}
          </Link>
          <Link
            to="/about"
            className={`link ${isActive("/about") ? "active" : ""}`}
          >
            {t("navigation.about")}
          </Link>
          {isAdmin(userContext) && (
            <Link className="link" onClick={toggleMenu}>
              {t("navigation.admin_menu")}
            </Link>
          )}
          <Select
            className="language-select"
            options={languageOptions}
            onChange={(selectedOption) => changeLanguage(selectedOption.value)}
            value={{ label: parseLanguage(), value: i18n.language }}
            styles={selectStyles}
            isSearchable={false}
          />
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ position: "relative", display: "inline-block" }}
          >
            <div className="link">
              <NameInitialsAvatar
                firstName={`${userContext.currentUser.firstname}`}
                lastName={`${userContext.currentUser.lastname}`}
                color={`${userContext.currentUser.color}`}
                height="40px"
                width="40px"
                fontSize="15px"
              />
            </div>
            <div className={`dropdown-menu ${isHovered ? "visible" : ""}`}>
              <Link to="/profile">{t("navigation.profile")}</Link>
              <Link onClick={handleLogout}>{t("navigation.logout")}</Link>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

const AdminMenu = ({ toggleMenu, isMenuOpen }) => (
  <div className={`admin-menu ${isMenuOpen ? "visible" : ""}`}>
    <Link className="admin-menu-link" to="/admin/types" onClick={toggleMenu}>
      Types
    </Link>
    <Link
      className="admin-menu-link"
      to="/admin/categories"
      onClick={toggleMenu}
    >
      Categories
    </Link>
    <Link className="admin-menu-link" to="/admin/users" onClick={toggleMenu}>
      Users
    </Link>
    <Link
      className="admin-menu-link"
      to="/admin/user_parents"
      onClick={toggleMenu}
    >
      Sosu to Parents
    </Link>
  </div>
);
